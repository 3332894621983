import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div style={{textAlign: 'center', paddingBottom: 50, paddingTop: 50}}>
      <h1 style={{fontSize: 40, textAlign: 'center', marginTop: 25, marginBottom: 25}}>¡PÁGINA NO ENCONTRADA!</h1>
      <h1 style={{color: 'red', fontSize: 60, marginBottom: 20}}>404</h1>
      <p>La página a la que apunta esta dirección no existe.</p>
      <Link to="/">Haga click aquí para volver al sitio de PsicoIntel.</Link>
    </div>
  </Layout>
)

export default NotFoundPage
